<template>
  <v-card
    min-height="100vh"
    flat
    color="transparent"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
  >
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">Settings</h1>
    </v-card-title>
    <app-tabs :tabs="tabs" optional>
      <router-view :key="$route.fullPath"></router-view>
    </app-tabs>
    <!-- <pre>{{ tabs }}</pre>
    <pre>{{
      $can.or("view_team_preferences|update_team_preferences")
        ? "true"
        : "false"
    }}</pre>
    <pre>{{ $can.userPermissions() }}</pre> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "app-setting-info",
    };
  },
  watch: {
    "$route.params.tab": {
      handler: function (val) {
        this.tab = val ? val : "info";
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["statistics", "workspace", "superiors"]),
    tabs() {
      let list = [];
      if (
        this.$can.or("view_team_preferences|update_team_preferences") ||
        this.user.is_owner ||
        this.user.is_primary_owner
      ) {
        list.push({
          to: { name: "app-settings-preferences" },
          name: "Preferences",
        });
      }
      if (
        this.$can.or("view_bot_responses|manage_bot_responses") ||
        this.user.is_owner ||
        this.user.is_primary_owner
      ) {
        list.push({
          to: { name: "app-settings-bot-responses" },
          name: "Bot Responses",
        });
      }
      if (
        this.$can.or(
          "manage_roles_permissions|add_roles|update_roles|assign_roles|revoke_roles"
        ) ||
        this.user.is_owner ||
        this.user.is_primary_owner
      ) {
        list.push({
          to: { name: "app-settings-user-management" },
          name: "Role & Permission",
        });
      }
      let others = [
        {
          to: { name: "app-settings-general-new-users" },
          name: "New Users",
          counts: this.statistics ? this.statistics.new_users_count : 0,
        },
        {
          to: { name: "app-settings-general-all-users" },
          name: "All Users",
          counts: this.statistics ? this.statistics.users_count : 0,
        },
      ];
      if (
        this.$can.and("view_team_info") ||
        this.user.is_owner ||
        this.user.is_primary_owner
      )
        list.push(...others);

      if (
        this.isModuleEnabled("regions") &&
        this.$can.or("view_region|manage_region")
      )
        list.push({
          to: { name: "app-settings-general-regions" },
          name: "Regions",
          counts: this.statistics ? this.statistics.regions_count : 0,
        });
      if (
        this.isModuleEnabled("sites") &&
        this.$can.or("view_office|manage_office")
      )
        list.push({
          to: { name: "app-settings-general-sites" },
          name: "Offices",
          counts: this.statistics ? this.statistics.sites_count : 0,
        });
      if (
        this.isModuleEnabled("data-centers") &&
        this.$can.or("view_data_center|manage_data_center")
      )
        list.push({
          to: { name: "app-settings-general-data-centers" },
          name: "Data Centers",
          counts: this.statistics ? this.statistics.data_centers_count : 0,
        });

      return list;
    },
  },
  created() {
    this.fetchWorkspaceRegions({ id: this.user.team_id });
    this.fetchWorkspaceDataCenters({ id: this.user.team_id });
    if (!this.workspace) {
      this.fetchWorkspaceInfo(this.user.team_id);
      this.fetchWorkspaceAllUsers(this.user.team_id);
    }
    if (!this.superiors.length) {
      this.fetchWorkspaceSuperiors(this.user.team_id);
    }
    if (!this.statistics.fetch) {
      this.fetchWorkspaceStatistics(this.user.team_id);
    }
  },
  methods: {
    ...mapActions("workspace", [
      "fetchWorkspaceStatistics",
      "fetchWorkspaceInfo",
      "fetchWorkspaceAllUsers",
      "fetchWorkspaceRegions",
      "fetchWorkspaceDataCenters",
      "fetchWorkspaceSuperiors",
    ]),
  },
};
</script>

<style lang="css" scoped></style>

<template>
  <app-modal
    :valid="valid"
    title="Add Time"
    v-model="open"
    max-width="800"
    :confirm-label="$can.and('manage_user_time') ? 'Submit' : 'Get Approval'"
    @confirmed="submit"
  >
    <v-card flat color="transparent">
      <v-card-text>
        <div
          :class="[mdUp ? 'xflex-row' : 'xflex-col']"
          class="xflex xw-full xjustify-start xitems-center xgap-[0.5em]"
        >
          <div
            :class="[mdUp ? 'xw-8/12' : 'xw-full']"
            class="xflex xmb-[1em] xflex-col xjustify-between xgap-[10px] xitems-stretch"
          >
            <div class="xmax-w-full xflex xflex-col">
              <label class="xfont-semibold xtext-[#2E2E2E]">
                User <sup class="xtext-red-600">*</sup>
              </label>
              <UserSelect
                class="xw-full"
                :rules="[requiredRule()]"
                v-model="selectedUser"
                return-object
                :paying-only="true"
                :disabled="disableSelectUser"
                prepend-inner-icon="mdi-account-search"
              ></UserSelect>
            </div>
          </div>
          <div
            :class="[mdUp ? 'xw-4/12 ' : 'xw-full']"
            class="xflex xmb-[1em] xflex-col xjustify-between xgap-[10px] xitems-stretch"
          >
            <div class="xmax-w-full xflex xflex-col">
              <label class="xfont-semibold xtext-[#2E2E2E]">
                Base on Timezone <sup class="xtext-red-600">*</sup>
              </label>
              <TimezonePicker
                class="xw-full"
                :rules="[requiredRule()]"
                v-model="selectedTz"
              ></TimezonePicker>
            </div>
          </div>
        </div>

        <v-sheet color="transparent" class="xflex xmb-[px] xflex-col xw-full">
          <div
            class="xw-full xmb-[5px] xh-[50px] xflex xflex-row xjustify-between xitems-center"
          >
            <div
              class="xp-[5px] xfont-semibold md:xw-2/12 xw-4/12 xtext-[#2E2E2E] xtext-left"
            >
              Task List
            </div>
          </div>
          <TimeLogInput
            :previous="null"
            :next="items[0] || null"
            v-model="firstItem"
          ></TimeLogInput>
          <TimeLogInput
            v-for="(item, index) in items"
            :key="index"
            editable-command
            :previous="index === 0 ? firstItem : items[index - 1]"
            :next="index === items.length - 1 ? lastItem : items[index + 1]"
            :value="items[index]"
            @input="items.splice(index, 1, $event)"
            @remove="items.splice(index, 1)"
          ></TimeLogInput>

          <v-row no-gutters class="py-2">
            <v-col :cols="mdUp ? 7 : 12" :offset="mdUp ? 1 : 0">
              <v-btn
                depressed
                dark
                block
                color="#7A38A3"
                class="text-none xfont-semibold !xborder-solid xborder-[#353535] xborder-[2px]"
                @click="insert"
              >
                <v-icon left>mdi-plus</v-icon> Add command
              </v-btn>
            </v-col>
          </v-row>
          <TimeLogInput
            :next="null"
            :previous="items.length ? items[items.length - 1] : firstItem"
            v-model="lastItem"
          ></TimeLogInput>
        </v-sheet>

        <v-alert
          icon="mdi-alert"
          type="error"
          class="xmax-w-[1024px] xmy-[5px] xmx-auto xw-full"
          outlined
          dense
          v-if="total_work <= 59"
        >
          Total working time must atleast 1 minute.
        </v-alert>

        <v-alert
          icon="mdi-alert"
          type="error"
          class="xmax-w-[1024px] xmy-[5px] xmx-auto xw-full"
          outlined
          dense
          v-if="hasBrbNotBack"
        >
          After a <strong>brb</strong> command a <strong>back</strong> command
          is required.
        </v-alert>

        <v-alert
          icon="mdi-alert"
          type="error"
          class="xmax-w-[1024px] xmy-[5px] xmx-auto xw-full"
          outlined
          dense
          v-if="switchButNoProject"
        >
          <strong>Project/Task</strong> is required for
          <strong>switch</strong> command.
        </v-alert>
      </v-card-text>
      <!-- <pre>{{ payload }}</pre> -->
    </v-card>
  </app-modal>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import UserSelect from "@/views/GlobalComponents/Forms/UserSelect.vue";
import TimezonePicker from "@/views/GlobalComponents/Forms/TimezonePicker.vue";
import CommandPicker from "@/views/GlobalComponents/Forms/CommandPicker.vue";
import TimeLogInput from "@/views/GlobalComponents/Forms/TimeLogInput.vue";

export default {
  props: {
    value: { type: Boolean, default: false },
    disableSelectUser: { type: Boolean, default: false },
    user: { type: [Object, String], default: undefined },
  },
  data() {
    return {
      open: false,
      submiting: false,
      selectedUser: null,
      selectedTz: moment.tz.guess(),
      firstItem: {
        command: "in",
        datetime: moment().format("YYYY-MM-DD HH:mm:00"),
        project: null,
      },
      lastItem: {
        command: "out",
        datetime: moment().format("YYYY-MM-DD HH:mm:00"),
        project: null,
      },
      items: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["allusers"]),
    valid() {
      return (
        this.payload.items.length >= 2 &&
        this.payload.user_id &&
        this.payload.tz &&
        !this.hasInvalidEntry &&
        !this.hasBrbNotBack &&
        !this.switchButNoProject &&
        this.total_work > 0
      );
    },
    hasBrbNotBack() {
      return _.cloneDeep(this.all_items).some((i, n) => {
        if (
          i.command === "brb" &&
          this.all_items[n + 1] &&
          this.all_items[n + 1].command !== "back"
        )
          return true;
        return false;
      });
    },
    switchButNoProject() {
      return _.cloneDeep(this.all_items).some((i, n) => {
        if (i.command === "switch" && !i.project) return true;
        return false;
      });
    },
    hasInvalidEntry() {
      return _.cloneDeep(this.all_items).filter((i) => !i.valid).length > 0;
    },
    getInvalidEntries() {
      return _.cloneDeep(this.all_items).filter((i) => !i.valid);
    },
    all_items() {
      return [this.firstItem, ...this.items, this.lastItem];
    },
    calculation() {
      let mpas = _.cloneDeep(this.all_items).map((i, n) => {
        let prev = this.all_items[n - 1]
          ? _.cloneDeep(this.all_items[n - 1])
          : null;
        if (!prev) return null;
        return {
          isWork: ["in", "back", "switch"].includes(prev.command),
          seconds: Math.abs(
            moment
              .duration(
                moment(prev.datetime, "YYYY-MM-DD HH:mm:00").diff(
                  moment(i.datetime, "YYYY-MM-DD HH:mm:00")
                )
              )
              .asSeconds()
          ),
          from: prev.datetime,
          to: i.datetime,
        };
      });
      return _.compact(mpas);
    },
    total_work() {
      return _.sumBy(
        _.cloneDeep(this.calculation).filter((i) => i.isWork),
        "seconds"
      );
    },
    total_break() {
      return _.sumBy(
        _.cloneDeep(this.calculation).filter((i) => !i.isWork),
        "seconds"
      );
    },
    payload() {
      return {
        user_id: this.selectedUser ? this.selectedUser.id : null,
        tz: this.selectedTz,
        items: _.cloneDeep(this.all_items).map((item, index) => {
          item.order = index;
          return item;
        }),
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
        if (!val) {
          this.resetInput();
        }
      },
      immediate: true,
      deep: true,
    },
    user: {
      handler: function (val) {
        if (typeof val === "string") {
          this.selectedUser = this.allusers.find((i) => i.id == val);
        } else this.selectedUser = val;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.allusers.length) {
      let currentUser = this.$store.getters["user"];
      this.fetchWorkspaceAllUsers(currentUser.team_id);
    }
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
    submit(reset) {
      this.submiting = true;
      this.$axios.get("sanctum/csrf-cookie").then(() => {
        this.$axios
          .post(`api/team-reports/add-time`, this.payload)
          .then(({ data }) => {
            this.appToast(data.message, "success");
            this.open = false;
            this.$event.$emit("refetch-records", true);
          })
          .catch((err) => {
            reset();
          })
          .finally(() => (this.submiting = false));
      });
    },
    getNextCommand(index) {
      if (index === 0)
        return {
          command: "brb",
          datetime: null,
          project: null,
        };
      return {
        command: "back",
        datetime: null,
        project: null,
      };
    },
    resetInput() {
      this.items = [];
      this.submiting = false;
      this.description = null;
    },
    insert() {
      const length = this.items.length;
      this.items.splice(length, 0, this.getNextCommand(length));
    },
  },
  components: { UserSelect, TimezonePicker, CommandPicker, TimeLogInput },
};
</script>

<style lang="scss" scoped></style>

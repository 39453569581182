var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xw-full",class:[_vm.tabletDown ? '' : 'xp-[1em]'],attrs:{"flat":"","color":"transparent","min-height":"100vh"}},[_c('v-card-title',[_c('h1',{staticClass:"xfont-[600] xtext-[24px] xleading-[29px]"},[_vm._v("Member Report")])]),_c('v-card',{staticClass:"!xw-full",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"xflex xjustify-start xgap-[0.5em] xitems-center"},[_c('UserSelect',{class:_vm.mobile ? '!xw-full' : "xmax-w-[200px]",attrs:{"return-object":"","has-all":""},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}}),_c('KirbyRangePicker',{class:_vm.mobile
            ? '!xw-full'
            : _vm.laptopUp
            ? "xmax-w-[350px]"
            : "xmax-w-[300px]",attrs:{"no-future":""},model:{value:(_vm.selectedRange),callback:function ($$v) {_vm.selectedRange=$$v},expression:"selectedRange"}}),_c('v-spacer'),(_vm.laptopUp && _vm.$can.and('manage_user_time'))?_c('btn-tooltip',{staticClass:"text-none",attrs:{"tip":"Manually add time log for this user","color":"primary"},on:{"click":function($event){_vm.add_time_modal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-calendar-plus ")]),_vm._v(" Add Time ")],1):_vm._e(),(
          _vm.laptopUp &&
          _vm.$can.and('manage_user_ptos') &&
          _vm.isModuleEnabled('ptos')
        )?_c('btn-tooltip',{staticClass:"text-none",attrs:{"tip":"Manually add PTO for this user","color":"primary"},on:{"click":function($event){_vm.add_pto_modal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-calendar-plus ")]),_vm._v(" Add PTO ")],1):_vm._e(),(_vm.laptopDown)?_c('btn-list',{attrs:{"icon":"mdi-chevron-down","btn-color":"primary","text-color":"primary","outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-form-dropdown")])]},proxy:true}],null,false,3638651365)},[_c('v-list',[(_vm.$can.and('manage_user_ptos'))?_c('v-list-item',{on:{"click":function($event){_vm.add_pto_modal = true}}},[_c('v-list-item-title',[_vm._v("Add PTO")])],1):_vm._e(),(_vm.$can.and('manage_user_time'))?_c('v-list-item',{on:{"click":function($event){_vm.add_time_modal = true}}},[_c('v-list-item-title',[_vm._v("Add Time")])],1):_vm._e()],1)],1):_vm._e(),(_vm.$can.and('download_record'))?_c('btn-export',{attrs:{"api-csv":_vm.csvUrl,"filename":_vm.fileName}}):_vm._e()],1),_c('v-card-text',[_c('app-table',{attrs:{"loading":_vm.fetching,"headings":_vm.headers,"items":_vm.reports,"empty-text":"No data found for given range"},scopedSlots:_vm._u([{key:"real_name",fn:function(ref){
        var item = ref.item;
return [_c('avatar',{attrs:{"size":"25","with-name":"","user":item}})]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.status.on_work)?_c('chip-in',{attrs:{"label":""}}):(item.status.on_break)?_c('chip-brb',{attrs:{"label":""}}):(item.status.on_out)?_c('chip-out',{attrs:{"label":""}}):_vm._e()]}},{key:"total_works",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secToHuman")(item.total_works," hours", " minutes"))+" ")])]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Log Details ")],1)],1),(_vm.$can.and('manage_user_ptos'))?_c('v-list-item',{on:{"click":function($event){return _vm.addPTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add PTO ")],1)],1):_vm._e(),(_vm.$can.and('manage_user_time'))?_c('v-list-item',{on:{"click":function($event){return _vm.addTime(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add Time ")],1)],1):_vm._e()],1)],1)]}}])})],1)],1),_c('AddTimeModal',{attrs:{"user":_vm.selectedUser},model:{value:(_vm.add_time_modal),callback:function ($$v) {_vm.add_time_modal=$$v},expression:"add_time_modal"}}),_c('AddPTOModal',{attrs:{"user":_vm.selectedUser},model:{value:(_vm.add_pto_modal),callback:function ($$v) {_vm.add_pto_modal=$$v},expression:"add_pto_modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./AddPTOModal.vue?vue&type=template&id=71a227e7&scoped=true&"
import script from "./AddPTOModal.vue?vue&type=script&lang=js&"
export * from "./AddPTOModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a227e7",
  null
  
)

export default component.exports